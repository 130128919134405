Eloca.behaviors.mask = function (context) {
    $(".date").mask("00/00/0000");

    $(".time").mask("00:00");
    $(".date_time").mask("00/00/0000 00:00:00");
    $(".cep").mask("00000-000");
    $(".cpf").mask("000.000.000-00", { reverse: true });
    $(".cnpj").mask("00.000.000/0000-00", { reverse: true });
    $(".money").mask("000.000.000.000.000,00", { reverse: true });
    $(".number").mask("0.000,000", { reverse: true });
    $(".integer").mask("000.000.000", { reverse: true });
    $(".agencia").mask("0000XZ", {
        translation: {
            X: {
                pattern: /[-]/,
                optional: true,
            },
            Z: {
                pattern: /[0-9]/,
                optional: true,
            },
        },
    });

    mascaraTelefones();

    $(".js-dropdown-telefone", context).on("change", function () {
        const box = $(this).closest(".input-group");
        $(".js-phone", box).data("mascara", $(this).data("mascara"));
        mascaraTelefones();
    })

    function mascaraTelefones()
    {
        $(".js-phone", context).each(function () {
            $(this).unmask();
            const mascara = $(this).data("mascara");
            if (mascara) {
                mascaraGenerica(this, mascara);
            } else {
                mascaraTelefonesBr(this);
            }
        });
    }
    function mascaraTelefonesBr(input)
    {
        const phoneMaskBehavior = function (val) {
            const numero = val.replace(/\D/g, "");
            if (numero.startsWith("0800", 0)) {
                return "0800 000 0000";
            }
            return numero.length === 11 ? "(00) 00000-0000" : "(00) 0000-00009";
        };
        const phoneOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(phoneMaskBehavior.apply({}, arguments), options);
            },
        };

        $(input).mask(phoneMaskBehavior, phoneOptions);
    }

    function mascaraGenerica(input, mascara)
    {
        $(input).mask(mascara, {
            reverse: false,
            translation: {
                'Z': {
                    pattern: /[0-9]/, optional: true
                }
            }
        });
    }
};
