window.isValidCPF = function (cpf) {
    if (cpf === "") {
        return true;
    }
    if (cpf === null) {
        return false;
    }
    cpf = cpf.replace(/\D/g, "");
    // tslint:disable-next-line: one-variable-per-declaration
    let numeros, digitos, soma, i, resultado, digitosIguais;
    digitosIguais = 1;
    if (cpf.length < 11) {
        return false;
    }
    for (i = 0; i < cpf.length - 1; i++) {
        if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            digitosIguais = 0;
            break;
        }
    }
    if (!digitosIguais) {
        numeros = cpf.substring(0, 9);
        digitos = cpf.substring(9);
        soma = 0;
        for (i = 10; i > 1; i--) {
            soma += numeros.charAt(10 - i) * i;
        }

        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        // tslint:disable-next-line: triple-equals
        if (resultado != digitos.charAt(0)) {
            return false;
        }
        numeros = cpf.substring(0, 10);
        soma = 0;
        for (i = 11; i > 1; i--) {
            soma += numeros.charAt(11 - i) * i;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        // tslint:disable-next-line: triple-equals
        if (resultado != digitos.charAt(1)) {
            return false;
        }
        return true;
    }
    return false;
};

window.isValidCNPJ = function (value) {
    if (value === "") {
        return true;
    }
    if (value === null) {
        return false;
    }

    let cnpj = value.replace(/\D/g, "");

    if (cnpj.length != 14) {
        return false;
    }

    if (/(d)\\1{13}/.test(cnpj)) {
        return false;
    }
    let soma = 0;
    // Valida primeiro dígito verificador
    for (let i = 0, j = 5; i < 12; i++) {
        soma += cnpj[i] * j;
        j = j == 2 ? 9 : j - 1;
    }

    let resto = soma % 11;

    if (cnpj[12] != (resto < 2 ? 0 : 11 - resto)) {
        return false;
    }
    soma = 0;
    // Valida segundo dígito verificador
    for (let i = 0, j = 6; i < 13; i++) {
        soma += cnpj[i] * j;
        j = j == 2 ? 9 : j - 1;
    }

    resto = soma % 11;

    return cnpj[13] == (resto < 2 ? 0 : 11 - resto);
}
