Eloca.behaviors.datepicker = function (context) {
    $(function ($) {
        $.datepicker.regional["pt-BR"] = {
            closeText: "Fechar",
            prevText: "&#x3c;Anterior",
            nextText: "Pr&oacute;ximo&#x3e;",
            currentText: "Hoje",
            monthNames: [
                "Janeiro",
                "Fevereiro",
                "Mar&ccedil;o",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            monthNamesShort: [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
            ],
            dayNames: [
                "Domingo",
                "Segunda-feira",
                "Ter&ccedil;a-feira",
                "Quarta-feira",
                "Quinta-feira",
                "Sexta-feira",
                "Sabado",
            ],
            dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
            weekHeader: "Sm",
            dateFormat: "dd/mm/yyy",
            firstDay: 0,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: "",
        };
        $.datepicker.setDefaults($.datepicker.regional["pt-BR"]);
    });

    if ($(".js-datepicker", context).length) {
        $(".js-datepicker", context).each(function (i, input) {
            let dataMinimo = null;
            let dataMaximo = null;
            if ($(input).data("minimo")) {
                dataMinimo = new Date($(input).data("minimo"));
            }
            if ($(input).data("maximo")) {
                dataMaximo = new Date($(input).data("maximo"));
            }

            let diasAtivos = [0, 1, 2, 3, 4, 5, 6];
            if ($(input).data("dias-retirada")) {
                diasAtivos = $(input).data("dias-retirada");
            }

            $(input).datepicker({
                dateFormat: "dd/mm/yy",
                minDate: dataMinimo,
                maxDate: dataMaximo,
                //beforeShowDay: filterDates
                beforeShowDay: function (date) {
                    let dia = date.getDay();
                    let mostraDia = diasAtivos.includes(dia);
                    return [mostraDia, ""];
                },
            });
        });
    }

    $(".js-datepicker-from", context).each(function (index, element) {
        let dataLimite = null;
        if ($(element).data("maximo")) {
            dataLimite = new Date($(element).data("maximo"));
        }
        let dataInicio = new Date();
        if ($(element).data("minimo")) {
            dataInicio = new Date($(element).data("minimo"));
        }
        let diasAtivos = [0, 1, 2, 3, 4, 5, 6];
        if ($(element).data("dias-retirada")) {
            diasAtivos = $(element).data("dias-retirada");
        }
        $(element)
            .datepicker({
                dateFormat: "dd/mm/yy",
                //defaultDate: "+1w",
                changeMonth: true,
                numberOfMonths: 1,
                minDate: dataInicio,
                maxDate: dataLimite,
                beforeShowDay: function (date) {
                    let dia = date.getDay();
                    let mostraDia = diasAtivos.includes(dia);
                    return [mostraDia, ""];
                },
            })
            .on("change", function () {
                $(".js-datepicker-to", context).datepicker(
                    "option",
                    "minDate",
                    getDate(this)
                );
            });
    });

    $(".js-datepicker-to", context).each(function (index, element) {
        let diasAtivos = [0, 1, 2, 3, 4, 5, 6];
        if ($(element).data("dias-retirada")) {
            diasAtivos = $(element).data("dias-retirada");
        }
        $(element, context)
            .datepicker({
                dateFormat: "dd/mm/yy",
                //defaultDate: "+1w",
                changeMonth: true,
                numberOfMonths: 1,
                beforeShowDay: function (date) {
                    let dia = date.getDay();
                    let mostraDia = diasAtivos.includes(dia);
                    return [mostraDia, ""];
                },
            })
            .on("change", function () {
                $(".js-datepicker-from", context).datepicker(getDate(this));
            });
    });

    function getDate(element) {
        var date;
        try {
            date = $.datepicker.parseDate("dd/mm/yy", element.value);
        } catch (error) {
            date = null;
        }
        return date;
    }

    function filterDates(date) {
        var data1 = new Date(date - 1);
        var data2 = new Date(date - 2);
        var no1 = $.datepicker.noWeekends(data1);
        var no2 = $.datepicker.noWeekends(data2);
        if (no1[0] && no2[0]) {
            return no1;
        }
    }
};
