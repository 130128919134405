window._ = require("lodash");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

window.Eloca = {
    behaviors: {},

    load: function (context) {
        for (const behavior in this.behaviors) {
            this.behaviors[behavior](context);
        }
    },
};

window.siteUrl = function (params) {
    return window.baseUrl + params;
};

//window.$ = window.jQuery = require("jquery");
window.Vapor = require("laravel-vapor");
//require("popper.js");
//require("bootstrap");
//require("jquery-mask-plugin");
//require("../../libs/jquery-ui.min.js");
window.moment = require("moment");
window.numeral = require("numeral");
require("numeral/locales/pt-br");
numeral.locale("pt-br");

window.Formatter = require("../../libs/formatter");

require("./behaviors");
require("../../components/mask");
require("../../components/datepicker");

require("./carrinho");

require("../../components/password");
require("../../components/validar-documentos");

(function () {
    Eloca.load(document);
})(jQuery);
